export function validateEmail(email) {
    const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
    return emailRegex.test(email)
}

export function validateNumber(number) {
    const phoneNumRegex = new RegExp(/^(09|\+639)\d{9}$/g)
    return phoneNumRegex.test(number)
}

export function validateFaxNumber(number) {
  const faxNumRegex = new RegExp(/^\d{3}\-\d{3}\-\d{4}$/g)
  return faxNumRegex.test(number)
}

export function validateName(name) {
    const nameNewRegex = new RegExp(/^(?![^a-zA-Z])[^0-9]+$/g)
    // const nameRegex = new RegExp(/^[^0-9]+$/g)
    return nameNewRegex.test(name)
}

export function validateTinNumber(tin) {
    const tinRegex = new RegExp(/^(?:\d{3}-\d{3}-\d{3}-\d{3,5}|\d{14})$/g)
    return tinRegex.test(tin)
}

export function validateDecimal(value) {
  const decimalRegex = /^\d+(\,\d+)?(\.\d+)?$/g;
  return decimalRegex.test(value);
}

export function validateZipCode(value) {
  return /^\d{4}/g.test(value);
}

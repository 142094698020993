<script setup>
import Loading from "@/components/base/Loading.vue";
import InvalidMessage from "@/components/misc/InvalidMessage.vue";
import InPageAlert from "@/components/base/InPageAlert.vue";
import {requestLogin} from "@/request";
import { useRouter} from "vue-router";
import {computed, reactive, ref} from "vue";
import {useModuleStatesStore} from "@/stores/useModuleStatesStore";
import {AlertConfigurations} from "@/service/alerts.service";

import useValidator from "@/composables/useValidator";
import {passwordValidator} from "@/validations";
import {ERROR_FIELD, FIELD_STATUS_COLORS} from "@/constants";
import usePayloadBuilder from "@/usePayloadBuilder";

const moduleState = useModuleStatesStore()
const router = useRouter();
const errorMessage = ref('Incorrect username and/or password.')
const credentials = reactive({
  username: '',
  password: ''
})

const isInvalidCredentials = ref(false);
const handleLogin = async() => {
  // let username = "admin"
  // let password = "acctg@2023";

  moduleState.isLoading = true
  const response = await requestLogin(credentials.username, credentials.password);
  // await getUserWidgets()
  moduleState.isLoading = false

  if(response.status === 500) {
    await AlertConfigurations.failedAlert('Internal Server Error', 'Server Error')
    return
  }
  if (response.status === 200){
    await router.push({ name: 'dashboard' });
    await AlertConfigurations.successAlert('Successfully Logged In', 'Logged In')
  }
  else {
    if(response.data.msg === "Error") errorMessage.value = `Cannot sign in. ${response.data.data.response}. Please contact the system administrator.`
    else errorMessage.value = 'Incorrect username and/or password.'
    isInvalidCredentials.value = true;
    setTimeout(() => {
      isInvalidCredentials.value = false;
    }, 5000)
    // await AlertConfigurations.failedAlert('You are not authorized to access this service.', 'Unauthorized')
  }
}
const icon = ref('fa-solid fa-eye-slash')
const showPassword = () => {
  let passwordInput = document.getElementById('password')
  if(passwordInput.type === 'password') {
    passwordInput.type='text'
    icon.value = 'fa-solid fa-eye'
  } else {
    passwordInput.type='password'
    icon.value = 'fa-solid fa-eye-slash'
  }
}

async function getUserWidgets() {
  let response = await usePayloadBuilder('dashboard', 'list').addPayload('conditions', {user: credentials.username}).execute();
  if(response) {
    if(response.data.data?.widget_list) {
      localStorage.setItem('widgets', JSON.stringify(response.data.data.widget_list))
    }
  }
}

const credentialsValidation = useValidator(credentials, {
  username: {required: true},
  password: {required: true, validations: [passwordValidator]}
})
const {
  validationObject: credentialValidationObject,
  validator: validateCredential
} = credentialsValidation

const validate = computed(() => {
  if(credentials.username && (credentialsValidation.validationObject.value.password.fieldStatus === 1)) return false;
  return true;
})
</script>

<template>
<div class="login-page-container text-center">
  <a class="fixed-bottom text-end text-light" style="font-size: 10px;" href="https://www.freepik.com/free-vector/gradient-cryptocurrency-concept_21534706.htm#query=finance%20background&position=27&from_view=keyword&track=ais_hybrid&uuid=06c9acd7-e600-44e5-8c0b-ae9799c4bfb2">Image by freepik</a>
  <div class="login-left-side">
    <div class="login-container">
      <div class="system-title">
        <h1 class="mainTitle">FUJITRANS</h1>
        <h3 class="subTitle">Logistics Philippines</h3>
        <h2 class="subSubTitle">ACCOUNTING SYSTEM</h2>
      </div>

      <div class="login-wrapper d-flex flex-column gap-2 py-3 mt-2">
        <InPageAlert v-if="isInvalidCredentials" type="alert-danger alert-sm" style="padding: 0 !important; margin: 0 !important;" :message="errorMessage" />
        <div>
          <label style="font-family: 'Inter Variable'; font-size: .9rem;">USERNAME</label>
          <div class="input-group">
            <span class="input-group-text d-flex justify-content-center" id="basic-addon1" style="width: 40px;"
                  :class="[FIELD_STATUS_COLORS[credentialValidationObject.username.fieldStatus]]">
              <font-awesome-icon :icon="['fas', 'user']" style="color:#9C9C9C;"/>
            </span>
            <input type="text" class="form-control rounded-end-2" placeholder="Enter username"
                   v-model="credentials.username"
                   @input="() => validateCredential('username')"
                   @blur="() => validateCredential('username')"
                   :class="[FIELD_STATUS_COLORS[credentialValidationObject.username.fieldStatus]]">
          </div>
          <InvalidMessage
              :is-visible="credentialValidationObject.username.fieldStatus === ERROR_FIELD"
              :message="credentialValidationObject.username.message"></InvalidMessage>
        </div>

        <div>
          <label style="font-family: 'Inter Variable'; font-size: .9rem;">PASSWORD</label>
          <div class="position-relative">
            <div class="input-group">
              <span class="input-group-text d-flex justify-content-center" id="basic-addon1" style="width: 40px;"
                :class="[FIELD_STATUS_COLORS[credentialValidationObject.password.fieldStatus]]">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="#9C9C9C" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"/></svg>
              </span>
              <input id="password" type="password" class="form-control rounded-end-2" placeholder="Enter password"
                   v-model="credentials.password"
                   @input="() => validateCredential('password')"
                   @blur="() => validateCredential('password')"
                   :class="[FIELD_STATUS_COLORS[credentialValidationObject.password.fieldStatus]]">
            </div>
            <font-awesome-icon :icon="icon" class="passwordStateIcon" @click="showPassword"/>
          </div>

          <InvalidMessage
              :is-visible="credentialValidationObject.password.fieldStatus === ERROR_FIELD"
              :message="credentialValidationObject.password.message"></InvalidMessage>
        </div>
        <button class="btn btn-sm btn-primary px-4 fs-5 fw-bold mt-2" :disabled="validate" style="font-family: 'Inter Variable', sans-serif;" @click="handleLogin">SIGN IN</button>
      </div>
    </div>
  </div>
  <div class="login-right-side">
    <div class="rotated-container shadow-lg "></div>
  </div>
</div>
  <Loading v-if="moduleState.isLoading" loading-message="Logging In..."/>
</template>

<style scoped>
.login-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #FAFAFA;
}
.login-container {
  text-align: start;
  width: 100%;
}

.system-title { font-family: var(--app-secondary-font-family) !important; }
.mainTitle { font-family: 'Quadrat Serial Bold', serif; font-size: 4.5rem; font-weight: 100; color: var(--brand-bg); line-height: 0.9; }
.subTitle { font-size: 2.2rem; font-weight: 550;}

.subSubTitle { color: var(--app-darker-gray); font-size: 2.05rem; font-weight: 700; }
label { font-size: 15px; }
input { padding: .5rem 1.2rem; border: 1px solid var(--app-light-gray); }
.password-container, .username-container { margin: 1rem 0; }
.pass-input-container { position: relative; }
.passwordStateIcon {
  z-index: 10;
  cursor: pointer;
  position: absolute;
  margin: 0 1rem;
  right: 0;
  bottom: 0;
  transform: translateY(-80%);
}
.login-left-side {
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}
.login-right-side {
  overflow: hidden;
  height: 100vh;
  width: 50%;
}
/* ATTRITION: Image by <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=544956">Gerd Altmann</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=544956">Pixabay</a>
Image by <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1881265">Gerd Altmann</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1881265">Pixabay</a>
Photo by <a href="https://unsplash.com/@goumbik?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Lukas Blazek</a> on <a href="https://unsplash.com/photos/turned-on-black-and-grey-laptop-computer-mcSDtbWXUZU?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
<a href="https://www.freepik.com/free-vector/stock-graph-market-finance-chart-growth-business-blue-bond-data-online-bank_10817218.htm#query=finance%20background&position=7&from_view=keyword&track=ais_hybrid&uuid=06c9acd7-e600-44e5-8c0b-ae9799c4bfb2">Image by zaie</a> on Freepik
<a href="https://www.freepik.com/free-vector/gradient-cryptocurrency-concept_21534706.htm#query=finance%20background&position=27&from_view=keyword&track=ais_hybrid&uuid=06c9acd7-e600-44e5-8c0b-ae9799c4bfb2">Image by freepik</a>
*/
.rotated-container {
  height: 150%;
  background-image: url("../assets/img/6379114.jpg");
  background-position: 70%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--app-main-theme);
}
@media (width >= 992px) {
  .rotated-container {
    background-position: 110% 10%;
    transform: rotate(15deg) translateX(30%) translateY(-20%);
  }
  .login-left-side {
    width: 70%;
    padding-left: 10rem;
  }
  .login-right-side { width: 70%; }
  .subSubTitle { line-height: 1.1; font-size: 2.4rem; }
}

@media (width >= 1440px) {
  .login-left-side { width: 50%; padding-left: 13rem; }
  .login-container { padding-right: 5rem; }
}
</style>
